import React from "react";
import { graphql } from "gatsby";
import Layout from "../layout";
import styled from "styled-components";
import SEO from "../components/seo";
import {
  ccbiffbamboomRegular,
  mainWhite,
  mainOrange,
  innerWidth,
  montserratRegular,
  montserratSemiBold,
  darkBlue,
  screen,
  transHover,
} from "../components/common/variables";
import plainBg from "../images/about/home-background.jpg";

const Wrapper = styled.div`
  background-image: url(${plainBg});
  background-repeat: repeat-y;
  background-position: center top;
  overflow: hidden;

  .banner {
    background-image: ${props => `url(${props.banner})`};
    background-repeat: no-repeat;
    background-position: left;
    background-size: 1000px;
    padding: 60px 0 112px 0;
    @media ${screen.small} {
      padding: 210px 0;
      background-size: cover;
      background-position: center;
    }
    @media ${screen.medium} {
      padding: 270px 0;
    }

    &__title-wrapper {
      max-width: ${innerWidth};
      margin: 0 auto;
      padding: 0 30px;
      @media ${screen.small} {
        padding: 0 50px;
      }

      .resource-title {
        color: ${mainWhite};
        font-family: ${ccbiffbamboomRegular};
        font-size: 3rem;
        max-width: 580px;
        @media ${screen.small} {
          text-align: left;
          font-size: 7rem;
          text-align: center;
        }

        &--orange {
          color: ${mainOrange};
          display: block;
          font-size: 1.3rem;
          margin-top: 20px;
          @media ${screen.small} {
            font-size: 3rem;
            margin-top: 0;
          }
        }
      }
    }
  }

  .intro-wrapper {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding: 40px 30px;
    @media ${screen.small} {
      padding: 100px 50px;
    }

    .intro {
      max-width: 1160px;
      &__title {
        color: ${darkBlue};
        font-size: 2rem;
        font-family: ${ccbiffbamboomRegular};
        margin-bottom: 30px;
        @media ${screen.small} {
          font-size: 3.5rem;
        }
      }

      &__description {
        max-width: 900px;
        p {
          color: ${darkBlue};
          font-family: ${montserratRegular};
          font-weight: 400;
          font-size: 1.05rem;
          margin-bottom: 20px;
          @media ${screen.small} {
            font-size: 1.25rem;
          }
        }
      }

      &__download-link {
        background-color: ${mainOrange};
        color: ${mainWhite};
        display: table;
        font-family: ${montserratSemiBold};
        font-size: 1.2rem;
        padding: 10px 20px;
        transition: ${transHover};
        margin-top: 40px;
        &:hover {
          background-color: ${darkBlue};
        }
      }
    }
  }
`;

const ResourceTeacherKit = ({ data }) => {
  const {
    title_tag,
    meta_description,
    heading,
    description,
    image_banner,
    downloadable_kit,
  } = data.content.data;

  return (
    <Layout>
      <SEO
        title={title_tag ? title_tag : `Resource Kit`}
        description={meta_description ? meta_description : `Martin Lysicrates`}
      />
      <Wrapper banner={image_banner && image_banner.url}>
        <div className="banner">
          <div className="banner__title-wrapper">
            <h1 className="resource-title">
              TEACHERS'{" "}
              <span className="resource-title--orange">RESOURCE KIT</span>
            </h1>
          </div>
        </div>

        <div className="intro-wrapper">
          <div className="intro">
            <h2 className="intro__title">{heading}</h2>
            <div
              className="intro__description"
              dangerouslySetInnerHTML={{ __html: description.html }}
            />
            <a
              className="intro__download-link"
              href={downloadable_kit.url}
              target="_blank"
            >
              Download the kit now!
            </a>
          </div>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default ResourceTeacherKit;

export const dataQuery = graphql`
  {
    content: prismicResourceKitPage {
      data {
        heading
        title_tag
        meta_description
        image_banner {
          url
        }
        description {
          html
        }
        downloadable_kit {
          url
        }
      }
    }
  }
`;
